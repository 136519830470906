/* All Modals */
.modal_overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1040;
	background-color: rgba(0, 0, 0, 0);
}

.modal_overlay_gray {
	background-color: rgba(0, 0, 0, 0.5);
}

.modal_wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	display: flex;
	align-items: center;
}

.modal_body {
	color: #000000;
	padding: 0px 10px 10px 10px;
}

.modal_div_button {
	display: flex;
	justify-content: center;
}

.modal_button {
	font-family: Arial, Helvetica, monospace;
	font-size: 12px;
	font-weight: bold;
	padding:5px 25px;
	margin: 5px;
	border-radius: 10px 10px 10px 10px;
	background: linear-gradient(#C5C5FF, #4d4df1, #C5C5FF);
	color: #FFFFFF;
	cursor:pointer;
}
  
.modal_button:disabled {
	background: #c0c0c0;
}

.modal_text {
	display: flex;
	margin-top: 10px;
	margin-bottom: 10px;
	white-space: nowrap;
}

.modal_text_left {
	margin-right: 0px;
}

.modal_text_right {
	flex: 2;
}

/* Modal Loader */
.modal_loader {
	z-index: 1100;
	color: black;
	background-color: white;
	position: relative;
	margin: auto;
	border-radius: 5px;
	border: 2px solid black;
	max-width: 500px;
	width: auto;
	min-width: 250px;
	display: block;
	height: auto;
	padding: 5px;
}

.modal_loader_img {
	display: flex;
	justify-content: left;
	padding-left: 10px;
	padding-right: 10px;
}

.txt_loader {
	margin-top: 0.5rem !important;
	margin-bottom: 0.25rem !important;
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.img_loader {
	width: 40px;
	object-fit: scale-down;
}

/* Modal Info */
.modal_info {
	z-index: 100;
	color: black;
	background-color: white;
	position: relative;
	margin: auto;
	border: 1px solid black;
	min-width: 350px;
	width: auto;
	display: block;
	height: auto;
}

.modal_header {
	color: #000000;
}

.modal_title {
	display: flex;
	align-items: center;
	padding: 5px 5px;
}

.modal_title_img {
	width: 20px;
	object-fit: scale-down;
}

.modal_level {
	background-color: #ffffff;
}

.modal_levelINFO {
	background-color: #ffffff;
}

.modal_levelDEBUG {
	background-color: #0000ff;
}

.modal_levelSUCCESS {
	background-color: #00ff00;
}

.modal_levelWARNING {
	background-color: #ffff00;
}

.modal_levelERROR {
	background-color: #ff0000;
}

/* Modal New Folder */
.modal_title_new_folder {
	background-color: #0078D7; /*e0e0ff*/
	color: white;
}

/* Modal Properties */
.modal_properties_preview {
	max-width: 400px;
	max-height: 200px;
	margin: 0px 10px 10px 10px;
	padding: 0px 10px;
	border: 1px solid #d5dae2;
	background-color: #f8f9fa;
	border-radius: 5px;
	overflow: auto;
	word-wrap: break-word;
}

/* Modal Upload */
.modal_upload_storage {
	border: 1px solid #d5dae2;
	display: block;
	white-space: nowrap;
}

.modal_upload_label_disabled {
	color: #a0a0a0;
}

.modal_upload_div_none {
	display: none;
}

.modal_upload_input_key {
	width: -webkit-fill-available;
	width: -moz-available;
	margin-left: 30px;
	margin-right: 5px;
}
