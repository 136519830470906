.diskTreeViewIcon {
	margin-right: 5px;
}

ul[role="tree"] {
	margin: 0;
	padding: 0;
	list-style: none;
}

ul[role="tree"] li {
	margin: 0;
	padding: 0;
	list-style: none;
}

[role="treeitem"][aria-expanded="false"]+[role="group"] {
	display: none;
}

[role="treeitem"][aria-expanded="true"]+[role="group"] {
	display: block;
}

[role="treeitem"][aria-expanded="false"]>ul {
	display: none;
}

[role="treeitem"][aria-expanded="true"]>ul {
	display: block;
	padding: 0px;
}

[role="treeitem"],
[role="treeitem"] span {
	margin: 0;
	padding: 0.125em;
	display: block;
}

[role="treeitem"]:focus {
	outline: 0;
}

[role="treeitem"][aria-selected="true"] {
	padding-left: 0px;
}

[selected-item="true"] {
	background-color: #CCE8FF;
}

[role="treeitem"].hover,
[role="treeitem"] div div[selected-item="false"]:hover {
	background-color: #EAF5FF;
}
