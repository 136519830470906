.Homepage {
  background-color: #F5F6F7;
  color: black;
}

.Homepage-header {
  font-size: calc(20px + 2vmin);
}

.Homepage-header-title {
  font-size: calc(25px + 2vmin);
}

.Homepage-header-subtitle {
  font-size: calc(10px + 2vmin);
}

.Homepage-div-title {
  margin-top: 50px;
  padding-left: 10%;
  padding-right: 10%;
}

.Homepage-menu {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.Homepage-button {
  margin: 0px;
  font-size: 24px;
}

.Homepage-detail {
  min-height: 200px;
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.Homepage-detail-left {
  width: 50%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  margin-left: 15%;
}

.Homepage-detail-right {
  width: 50%;
  min-height: 300px;
  display: flex;
  text-align: left;
  align-content: center;
  margin-right: 15%;
}

.Homepage-detail-title {
  padding-top: 75px;
  font-size: calc(20px + 4vmin);
}

.Homepage-detail-text {
  padding-top: 30px;
  font-size: calc(10px + 2vmin);
}

.Homepage-detail-one {
  width: 100%;
  border: 2px solid blue;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.Homepage-detail-four {
  width: 25%;
  border: 2px solid blue;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.Homepage-detail-text-four {
  text-align: left;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: calc(5px + 2vmin);
}
